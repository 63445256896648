import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { get } from 'lodash';
import ThreeDots from '../../components/ThreeDots/ThreeDots';
import DropdownBtn from '../../components/DropdownBtn/DropdownBtn';
import Loader from '../../components/Loader/Loader';
import {
  fetchApplicantsData,
  moveApplicants,
} from '../../redux/applicantsSlice';
import { hideToast, showToast } from '../../redux/toastSlice';
import fullUrl from '../../utils/fullUrl';
import fullUrlResume from '../../utils/fullUrlResume';
import {
  iconSearch,
  iconPositive,
  iconDownCaret,
  iconFilter,
  iconDownload,
  iconClockGreen,
  iconClockOrange,
  iconClockRed,
  iconChecked,
} from '../../assets';
import styles from './Applicants.module.css';

const getClassName = (score) => {
  if (score < 50) return styles.lessThan50;
  if (score < 75) return styles.lessThan75;
  return styles.lessThan100;
};

const getImageSrc = (score) => {
  if (score < 50) return iconClockRed;
  if (score < 75) return iconClockOrange;
  return iconClockGreen;
};
const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};
function unixTimestampToDate(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000);
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const month = months[date.getMonth()];
  const day = date.getDate();
  return `${month} ${day}`;
}

const Applicants = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { applicantId } = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const [shouldOpenDropdownBtn, setShouldOpenDropdownBtn] = useState(false);
  const [readMoreMap, setReadMoreMap] = useState({});

  const { data, hasMore, teams, restAll } = useSelector(
    (state) => state.applicants
  );
  const [selectedIds, setSelectedIds] = useState({});

  const [selectedOptions, setSelectedOptions] = useState({
    team: '',
    postedOn: '6Months',
    status: 'active',
    stage: 'applied',
    sortBy: "mRecent",
    ...(applicantId && { job_id: applicantId }),
  });

  const handleSelectChange = (e) => {
    setSelectedOptions({
      ...selectedOptions,
      [e.target.name]: e.target.value,
    });
  };

  const handleFilter = async (e, stage) => {
    setShowLoader(true);
    let filteredOptions = Object.entries(selectedOptions).reduce(
      (acc, [key, value]) => {
        if (value !== '') {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );
    if (stage) {
      filteredOptions.stage = stage;
      setSelectedOptions({
        ...selectedOptions,
        stage,
      });
    }
    await dispatch(fetchApplicantsData(filteredOptions));
    setShowLoader(false);
  };

  useEffect(() => {
    dispatch(
      fetchApplicantsData({
        postedOn: '6Months',
        status: 'active',
        stage: 'applied',
        sortBy: "mRecent",
        ...(applicantId && { job_id: applicantId }),
      })
    );
  }, [dispatch]);

  const fetchMoreData = () => {
    dispatch(
      fetchApplicantsData({
        ...selectedOptions,
        offset: data?.length || 20,
        replaceExisting: false,
      })
    );
  };
  function handleReadMore(id) {
    setReadMoreMap(prevReadMoreMap => ({
      ...prevReadMoreMap,
      [id]: !prevReadMoreMap[id]
    }));
  }

  const handleBoxSelect = (id) => {
    setSelectedIds((prevSelectedIds) => ({
      ...prevSelectedIds,
      [id]: !prevSelectedIds[id],
    }));
  };

  const handleMoveApplicants = async (stage) => {
    setShowLoader(true);
    const response = await dispatch(
      moveApplicants({
        applicantIds: Object.keys(selectedIds).filter(
          (key) => selectedIds[key]
        ),
        moveTo: stage,
      })
    );
    setSelectedIds({});
    if (response.type === 'applicants/moveApplicants/fulfilled') {
      handleFilter(null, selectedOptions.stage);
    } else {
      setShowLoader(false);
    }
  };

  const showFooter = Object.values(selectedIds).filter(Boolean).length;
  return (
    <section className={styles.applicantsMainContent}>
      <header className={styles.applicantsHeader}>
        <div className={styles.applicantsTitleWrap}>
          <h1 className={styles.applicantsTitle}>Applicants</h1>
          <span className={styles.applicantsTrialWrap}>
            <span className={styles.applicantsTrialDays}>
              {/* {get(data, 'trial_left', 0)} days */}
              {get({}, 'trial_left', 0)} days
            </span>{' '}
            of trial left
          </span>
        </div>
        <div className={styles.applicantsHeaderBox}>
          <div style={{ position: 'relative', marginRight: '1.6rem' }}>
            <img
              src={iconSearch}
              alt='search'
              style={{
                position: 'absolute',
                top: '50%',
                left: '10px',
                transform: 'translateY(-50%)',
              }}
            />
            {/* <img src={iconSearch} alt='search' /> */}
            <input
              className={styles.applicantsSearchWrap}
              placeholder='Search for applicants and applicants'
              style={{ paddingLeft: '40px', textAlign: 'left' }}
            />
          </div>
          <button
            className={styles.applicantsJobCta}
            onClick={() => {
              navigate('/client/create-job');
            }}>
            <img src={iconPositive} alt='positive' />
            <span className={styles.applicantsJobText}>Create a job</span>
          </button>
        </div>
      </header>

      <div className={styles.applicantsFiltersBox}>
        <div className={styles.applicantsFilter}>
          <span className={styles.applicantsFilterTitle}>status</span>
          <div className={styles.applicantsSelectContainer}>
            <select
              className={styles.applicantsStyledSelect}
              name='status'
              value={selectedOptions.status}
              onChange={handleSelectChange}>
              <option value='active'>Active</option>
              <option value='inactive'>Inactive</option>
            </select>
            <img
              className={styles.selectIcon}
              src={iconDownCaret}
              alt='icondowncaret'
            />
          </div>
        </div>

        <div className={styles.applicantsFilter}>
          <span className={styles.applicantsFilterTitle}>teams</span>
          <div className={styles.applicantsSelectContainer}>
            <select
              className={styles.applicantsStyledSelect}
              name='team'
              value={selectedOptions.team}
              onChange={handleSelectChange}>
              <option defaultValue='' value={''}>
                All teams
              </option>
              {data &&
                teams &&
                teams.length > 0 &&
                teams.map((team) => (
                  <option key={team} value={team}>
                    {team}
                  </option>
                ))}
            </select>
            <img
              className={styles.selectIcon}
              src={iconDownCaret}
              alt='icondowncaret'
            />
          </div>
        </div>

        <div className={styles.applicantsFilter}>
          <span className={styles.applicantsFilterTitle}>Posted on</span>
          <div className={styles.applicantsSelectContainer}>
            <select
              className={styles.applicantsStyledSelect}
              name='postedOn'
              value={selectedOptions.postedOn}
              onChange={handleSelectChange}>
              <option value='1Day'>Last 1 day</option>
              <option value='1Week'>Last 1 week</option>
              <option value='1Month'>Last 1 month</option>
              <option value='3Months'>Last 3 months</option>
              <option value='6Months'>Last 6 months</option>
              <option value='1Year'>Last 1 Year</option>
              <option value='allTime'>All time</option>
            </select>
            <img
              className={styles.selectIcon}
              src={iconDownCaret}
              alt='icondowncaret'
            />
          </div>
        </div>

        <div className={styles.applicantsFilter}>
          <span className={styles.applicantsFilterTitle}>Sort by</span>
          <div className={styles.applicantsSelectContainer}>
            <select
              className={styles.applicantsStyledSelect}
              name='sortBy'
              value={selectedOptions.sortBy}
              onChange={handleSelectChange}>
              <option value='mRecent'>Most Recent</option>
              <option value='lRecent'>Least Recent</option>
              <option value='mRelevant'>Most Relevant</option>
              <option value='lRelevant'>Least Relevant</option>
              <option value='mCredible'>Most Credible</option>
              <option value='lCredible'>Least Credible</option>
            </select>
            <img
              className={styles.selectIcon}
              src={iconDownCaret}
              alt='icondowncaret'
            />
          </div>
        </div>
        <button className={styles.applicantsFilterCTA} onClick={handleFilter}>
          <img src={iconFilter} alt='filter' />
          <span className={styles.applicantsFilterText}>Add filters</span>
        </button>
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.selectOptionWrapper}>
          <div
            className={`${styles.selectBox} ${selectedOptions.stage === 'applied' ? styles.selectedBox : ''
              }`}
            onClick={() => handleFilter(null, 'applied')}>
            <span>Applied</span>
            <span>{restAll.applied}</span>
          </div>
          <div
            className={`${styles.selectBox} ${selectedOptions.stage === 'recommended' ? styles.selectedBox : ''
              }`}
            onClick={() => handleFilter(null, 'recommended')}>
            <span>Recommended</span>
            <span>{restAll.recommended}</span>
          </div>
          <div
            className={`${styles.selectBox} ${selectedOptions.stage === 'shortlisted' ? styles.selectedBox : ''
              }`}
            onClick={() => handleFilter(null, 'shortlisted')}>
            <span>Shortlisted</span>
            <span>{restAll.shortlisted}</span>
          </div>
          <div
            className={`${styles.selectBox} ${selectedOptions.stage === 'interviewed' ? styles.selectedBox : ''
              }`}
            onClick={() => handleFilter(null, 'interviewed')}>
            <span>Interviewed</span>
            <span>{restAll.interviewed}</span>
          </div>
          <div
            className={`${styles.selectBox} ${selectedOptions.stage === 'role_offered' ? styles.selectedBox : ''
              }`}
            onClick={() => handleFilter(null, 'role_offered')}>
            <span>Role offered</span>
            <span>{restAll.role_offered}</span>
          </div>
          <div
            className={`${styles.selectBox} ${selectedOptions.stage === 'hired' ? styles.selectedBox : ''
              }`}
            onClick={() => handleFilter(null, 'hired')}>
            <span>Joined</span>
            <span>{restAll.joined}</span>
          </div>
        </div>

        <div className={styles.infoWrapper}>
          <span className={styles.infoText}>All Applicants</span>
          <button className={styles.uploadDownloadCta}>
            <img src={iconDownload} alt={'iconDownload'} />
            Download Applicants
          </button>
        </div>

        <div
          className={
            styles.applicantsRowDesc + ' ' + styles.applicantsRowDescExtra
          }>
          <div className={styles.empty}>
            <span
              style={{ visibility: 'hidden' }}
              className={styles.box}></span>
          </div>

          <div className={styles.titleRowWrapper}>
            <span className={styles.name}>Name</span>
            <span className={styles.relevancy}>Relevancy</span>
            <span className={styles.highlights}>Highlights</span>
            <span className={styles.credibility}>Credibility</span>
            <span className={styles.lastUpdated}>Last updated</span>
            <span style={{ width: '2.5rem' }}></span>
          </div>
        </div>
        <InfiniteScroll
          dataLength={data?.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>You have seen all applicants</b>
            </p>
          }
          className={styles.applicantsSection}>
          {data?.map(
            ({
              id,
              applicant_title,
              team,
              applicants,
              posted_by,
              posted_on,
              status,
              resume_filename,
              current_role,
              name,
              relevancy_score,
              highlights,
              updated_at,
              internal = true,
              credibility_score,
            }) => {
              const isSelected = selectedIds[id];
              return (
                <div
                  className={`${styles.applicantsRow} ${isSelected ? styles.selectedApplicantsRow : ''
                    }`}
                  key={id || `${posted_by}_${applicant_title}_${posted_on}`}>
                  <div
                    className={
                      styles.applicantsRowDesc +
                      ' ' +
                      styles.applicantsRowDescInner
                    }>
                    <div
                      className={styles.empty}
                      onClick={() => handleBoxSelect(id)}>
                      {isSelected ? (
                        <img
                          src={iconChecked}
                          alt='Selected'
                          className={styles.box + ' ' + styles.checkedBox}
                        />
                      ) : (
                        <span className={styles.box}></span>
                      )}
                    </div>
                    <div className={styles.titleRowWrapper}>
                      <div className={styles.name}>
                        {/* <span className={styles.nameText}>{name}</span> */}
                        <Link
                          className={styles.nameText}
                          to={`/client/applicant-detail/${id}`}>
                          {name}
                        </Link>
                        <div>
                          <span className={styles.roleText}>{current_role}</span>
                          <span className={styles.dot}></span>
                          <span className={styles.wordChip}>{internal ? "internal" : "external"}</span>

                        </div>
                      </div>
                      <div className={styles.relevancy}>
                        <span
                          className={`${styles.relevancyText} ${getClassName(
                            relevancy_score
                          )}`}>
                          {relevancy_score?.toFixed(2)}%
                        </span>
                        <img
                          src={getImageSrc(relevancy_score)}
                          alt='Relevancy Icon'
                          className={styles.scoreIcon}
                        />
                      </div>
                      <div className={styles.highlights}>
                        <ul className={styles.highlightListWrapper}>
                          {
                            readMoreMap[id] ? <>
                              {highlights.map((highlight, index) => {
                                return (
                                  <li key={index} className={styles.highlightList} onClick={() => handleReadMore(id)}>
                                    {highlight}

                                  </li>
                                );
                              })}
                            </> : <>
                              {highlights.slice(0, 2).map((highlight, index) => {
                                return (
                                  <li key={index} className={styles.highlightList}>
                                    {index === 0
                                      ? truncateText(highlight, 35)
                                      : truncateText(highlight, 25)}
                                    {index === 1 && (
                                      <>
                                        {highlight.length <= 25 ? '...' : null}
                                        <button
                                          className={styles.readMoreText}
                                          onClick={() => handleReadMore(id)}>
                                          Read more
                                        </button>
                                      </>
                                    )}
                                  </li>
                                );
                              })}
                            </>
                          }

                        </ul>
                      </div>
                      <div className={styles.credibility}>
                        <span className={styles.credibilityText}>
                          {parseFloat(credibility_score.toFixed(2))}%
                        </span>
                      </div>
                      <div className={styles.lastUpdated}>
                        <span className={styles.lastUpdateText}>
                          {unixTimestampToDate(updated_at)}
                        </span>
                      </div>
                      <ThreeDots
                        menuItems={[
                          {
                            text: 'View resume',
                            onClick: () => {
                              const embedLink = fullUrlResume(resume_filename);
                              window.open(
                                embedLink,
                                '_blank',
                                'noopener,noreferrer'
                              );
                            },
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </InfiniteScroll>
      </div>
      {showFooter ? (
        <div className={styles.submitButton}>
          <span className={styles.footerText}>
            {showFooter} Applicants selected
          </span>
          <button
            type='button'
            className={styles.btnCancel}
            onClick={() => setSelectedIds({})}>
            Deselect all
          </button>
          <button
            onClick={() =>
              setShouldOpenDropdownBtn((prev) => {
                return !prev;
              })
            }
            className={styles.btnSubmit}>
            <span>Move Applicants </span>
            {shouldOpenDropdownBtn && (
              <DropdownBtn
                menuItems={[
                  {
                    text: 'Applied',
                    onClick: () => {
                      handleMoveApplicants('applied');
                    },
                  },
                  {
                    text: 'Shortlisted',
                    onClick: () => {
                      handleMoveApplicants('shortlisted');
                    },
                  },
                  {
                    text: 'Interviewed',
                    onClick: () => {
                      handleMoveApplicants('interviewed');
                    },
                  },
                  {
                    text: 'Role offered',
                    onClick: () => {
                      handleMoveApplicants('role_offered');
                    },
                  },
                  {
                    text: 'Joined',
                    onClick: () => {
                      handleMoveApplicants('hired');
                    },
                  },
                ]}
              />
            )}
          </button>
        </div>
      ) : null}
      <Loader isLoading={showLoader} />
    </section>
  );
};

export default Applicants;
