import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ThreeDots from '../../components/ThreeDots/ThreeDots';
import { fetchApplicantDetailData, updateJobDetail } from '../../redux/applicantDetail';
import { updateStatus } from '../../redux/jobsSlice';
import { openModal } from '../../redux/bulkUpload';
import { showToast } from '../../redux/toastSlice';
import Loader from '../../components/Loader/Loader';
import fullUrl from '../../utils/fullUrl';
import fullUrlResume from '../../utils/fullUrlResume';
import {
  iconSearch,
  iconPositive,
  iconBackGreen,
  iconClockGreen,
  iconClockOrange,
  iconClockRed,
  iconEmail,
  iconPhone
} from '../../assets';
import styles from './ApplicantDetails.module.css';
import { isEmpty, get, isArray } from 'lodash';

function isPdf(value) {
  if (typeof value === 'string' && value.endsWith('.pdf')) {
    return true;
  }
  return false;
}

const relevencyMap = {
  high: {
    src: iconClockGreen,
    backgroundColor: '#18ae1e1a',
    color: '#18AE1E',
  },
  mid: {
    src: iconClockOrange,
    color: '#D86D3A',
    backgroundColor: '#D86D3A1A',
  },
  low: {
    src: iconClockRed,
    backgroundColor: '#FF57571A',
    color: '#FF5757',
  },
};
const getColor = (score) =>
  score > 70 ? 'high' : score > 30 ? 'mid' : 'low';

const ApplicantDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { applicantdetailId } = useParams();
  const { data } = useSelector((state) => state.applicantDetail);
  console.log('data: ', data);
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    if (!applicantdetailId) return;
    console.log('applicantdetailId: ', applicantdetailId);
    dispatch(fetchApplicantDetailData({ applicant_id: applicantdetailId }));
  }, [dispatch, applicantdetailId]);

  const handleBulkUpload = () => {
    dispatch(openModal(applicantdetailId));
  };

  const profileDetailValue = (value) => {
    if (isPdf(value)) {
      return (
        <span
          className={styles.resumeSpan}
          onClick={() => {
            const embedLink = fullUrlResume(value);
            window.open(embedLink, '_blank', 'noopener,noreferrer');
          }}
        >
          View resume
        </span>
      );
    }
    return value;
  }

  if (isEmpty(data)) return null;

  return (
    <section className={styles.jobsMainContent}>
      <header className={styles.jobsHeader}>
        <button className={styles.btnEmpty}>
          <img
            style={{ paddingRight: '4px' }}
            src={iconBackGreen}
            alt='go back'
          />
          <span>Back to Applicants</span>
        </button>
        <div className={styles.jobsHeaderBox}>
          <div style={{ position: 'relative', marginRight: '1.6rem' }}>
            <img
              src={iconSearch}
              alt='search'
              style={{
                position: 'absolute',
                top: '50%',
                left: '10px',
                transform: 'translateY(-50%)',
              }}
            />
            {/* <img src={iconSearch} alt='search' /> */}
            <input
              className={styles.jobsSearchWrap}
              placeholder='Search for applicants and jobs'
              style={{ paddingLeft: '40px', textAlign: 'left' }}
            />
          </div>
          <button
            className={styles.jobsJobCta}
            onClick={() => {
              navigate('/client/create-job');
            }}>
            <img src={iconPositive} alt='positive' />
            <span className={styles.jobsJobText}>Create a job</span>
          </button>
        </div>
      </header>

      <div className={styles.contentWrapper}>
        <div className={styles.columnLarge}>
          <div className={styles.rowWrapper}>
            <div className={styles.rowPadding}>
              <div className={styles.rowHeader}>
                <div className={styles.nameWrapper}>
                  <span className={styles.headerText}>{data.name}</span>
                  <div className={styles.captionWrapper}>
                    <span>{data.role}</span>
                    <span className={styles.dot}></span>
                    <span>{data.team}</span>
                  </div>

                </div>
                <div >
                  <div className={styles.numberWrapper}>
                    <div
                      className={styles.relevencyBox}
                      style={{
                        backgroundColor:
                          relevencyMap[getColor(data.normalized_relevancy_score)]
                            .backgroundColor,
                      }}>
                      <div className={styles.relevency}>
                        <span
                          className={styles.relevencyBoxScore}
                          style={{
                            color:
                              relevencyMap[getColor(data.normalized_relevancy_score)].color,
                          }}>
                          {Number(get(data, 'normalized_relevancy_score', 0)).toFixed(2)}%
                        </span>
                        <img
                          className={styles.relevencyBoxImg}
                          src={relevencyMap[getColor(data.normalized_relevancy_score)].src}
                          alt='clock'
                        />
                      </div>
                      <span className={styles.relevencyBoxText}>Relevancy</span>
                    </div>
                    <div
                      className={styles.relevencyBox}
                      style={{
                        backgroundColor:
                          "#F5F9FE",
                      }}>
                      <div className={styles.relevency}>
                        <span
                          className={styles.relevencyBoxScore}
                          style={{
                            color:
                              "#2A2F5A",
                          }}>
                          {Number(get(data, 'normalized_credibility_score', 0)).toFixed(2)}%
                        </span>
                      </div>
                      <span className={styles.relevencyBoxText}>Credibility</span>
                    </div>
                  </div>

                  <div className={styles.scoreWrapper}>
                    <span>JD Score: {data.jd_score}</span>
                    <span>Criteria Scrore: {data.criteria_score}</span>
                    <span> KRA Score: {data.kra_score}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.rowWrapper}>
            <div className={styles.rowPadding}>
              <div className={styles.rowHeader}>
                <span className={styles.headerText}>Highlights (AI generated)</span>
              </div>

              {isArray(data.highlights) && data.highlights.length > 0 ? (
                <div className={styles.infoRowExtra}>
                  <div className={styles.infoColumn}>
                    <ul className={styles.ulWrapper}>
                      {data.highlights.map(highlight => (
                        <li className={styles.highlightText} key={highlight}>{highlight}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : null}



            </div>
          </div>
          <div className={styles.rowWrapper}>
            <div className={styles.rowPadding}>
              <div className={styles.rowHeader}>
                <span className={styles.headerText}>Job Criteria</span>
              </div>
              <div className={styles.infoGrid}>
                {Object.keys(data["Profile Details"])
                  .map((profileDetailsKey, index) => {
                    return (
                      <React.Fragment key={profileDetailsKey}>
                        <div
                          className={styles.infoColumn}
                          key={index}>
                          <span className={styles.label}>
                            {profileDetailsKey}
                          </span>
                          <span className={styles.labelText}>
                            {profileDetailValue(data["Profile Details"][profileDetailsKey])}
                          </span>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>
          </div>


        </div>
        <div className={styles.columnSmall}>
          <div className={styles.containerWrapper}>
            <div className={styles.rowHeader}>
              <span className={styles.headerText}>Contact Details</span>
            </div>

            <div className={styles.detailsWrapper}>
              <div className={styles.contactWrapper}>
                <img src={iconEmail} alt={"email"} />
                <div className={styles.contactDetails}>
                  <span>Email ID</span>
                  <span>{data.email}</span>
                </div>
              </div>
              <div className={styles.contactWrapper}>
                <img src={iconPhone} alt={"phone"} />
                <div className={styles.contactDetails}>
                  <span>Email ID</span>
                  <span>{data.phone}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader isLoading={showLoader} />
    </section>
  );
};

export default ApplicantDetail;
